<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { HTML_TAG } from '@fmpedia/enums'

export default {
  name: 'ACombinedComponent',
  props: {
    gridContentSettings: propValidator([PROP_TYPES.OBJECT]),
    breakpoint: propValidator([PROP_TYPES.NUMBER]),
    data: propValidator([PROP_TYPES.OBJECT]),
    position: propValidator([PROP_TYPES.NUMBER])
  },
  computed: {
    breakpointSettings() {
      if (
        !this.gridContentSettings ||
        !this.breakpoint ||
        this.position == null
      ) {
        return []
      }

      return this.gridContentSettings[this.breakpoint]?.[this.position] || []
    }
  },
  /**
   * ToDo: We can try to use template instead of render function (not critical)
   */
  render(h) {
    try {
      const componentParts = this.breakpointSettings
        .map(settings => {
          if (!settings?.slotName || !this.$scopedSlots[settings.slotName]) {
            return null
          }

          const data = settings?.getData({
            data: this.data,
            position: this.position
          })

          return this.$scopedSlots[settings.slotName]({
            data
          })
        })
        .filter(v => !!v)

      return h(HTML_TAG.DIV, {}, componentParts)
    } catch (err) {
      return null
    }
  }
}
</script>

<style scoped lang="scss"></style>
